import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../styles/CookieConsent.css";

const CookieConsent = () => {
  const { t } = useTranslation();
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    const storedConsent = localStorage.getItem("cookieConsent");
    if (storedConsent) {
      setConsent(true);
    }
  }, []);

  const acceptCookies = () => {
    setConsent(true);
    localStorage.setItem("cookieConsent", "true");
  };

  if (consent) return null;

  return (
    <div className="cookie-consent-banner">
      <p>
        {t("cookieConsent.message")}{" "}
        <a href="/privacy-policy">{t("cookieConsent.learnMore")}</a>
      </p>
      <button onClick={acceptCookies}>{t("cookieConsent.accept")}</button>
    </div>
  );
};

export default CookieConsent;
