import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import HorizontalSlider from "../components/HorizontalSlider";
import SubscriptionForm from "../components/SubscriptionForm"; // Import the Subscription Form
import "../styles/NewsArticlePage.css";
import { getUserLanguage } from "../utils";

// Utility function to strip HTML tags
const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const NewsArticlePage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [article, setArticle] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedArticles, setRelatedArticles] = useState([]); // For related articles

  useEffect(() => {
    // Fetch the latest news
    const userLanguage = getUserLanguage();

    // Set the URL dynamically based on the user's language
    const NEWS_JSON_URL = userLanguage === 'pt' ? '/news_pt.json' : '/news_en.json';
    const PRODUCTS_JSON_URL = userLanguage === 'pt' ? '/products_pt.json' : '/products_en.json';
    fetch(NEWS_JSON_URL)
      .then((response) => response.json())
      .then((data) => {
        const foundArticle = data.news.find(
          (newsItem) => newsItem.id === parseInt(id)
        );
        setArticle(foundArticle);
        

        // Set the URL dynamically based on the user's language
        
        if (foundArticle?.productCategories?.length > 0) {
          fetch(PRODUCTS_JSON_URL)
            .then((response) => response.json())
            .then((productData) => {
              const products = productData.products.filter((product) =>
                product.categories.some((category) =>
                  foundArticle.productCategories.includes(category)
                )
              );
              setRelatedProducts(products);
            });

          // Find related articles based on shared categories
          const related = data.news.filter(
            (newsItem) =>
              newsItem.id !== foundArticle.id &&
              foundArticle.productCategories.some((category) =>
                newsItem.productCategories.includes(category)
              )
          );
          setRelatedArticles(related);
        }
      })
      .catch((error) => console.error("Error fetching news:", error));
  }, [id]);

  if (!article) {
    return <div>{t("newsArticlePage.loading")}</div>;
  }

  // Strip HTML tags from the article content
  const plainTextContent = stripHtmlTags(article.content);

  // JSON-LD structured data
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: article.title,
    image: article.image,
    datePublished: article.date,
    author: {
      "@type": "Person",
      name: article.author || "Unknown",
    },
    keywords: article.keywords ? article.keywords.join(", ") : "",
    articleBody: plainTextContent,
  };

  return (
    <div className="news-article-page">
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={plainTextContent.substring(0, 150)} />
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>

      <h1>{article.title}</h1>
      <div className="news-article-meta">
        <p className="news-article-date">
          {new Date(article.date).toLocaleDateString()}
        </p>
        {article.author && (
          <p className="news-article-author">
            {t("newsArticlePage.author")}: {article.author}
          </p>
        )}
      </div>
      <img
        src={article.image}
        alt={article.title}
        className="news-article-image"
      />
      <div dangerouslySetInnerHTML={{ __html: article.content }} />

      {/* Subscription Section */}
      <section className="subscription-section">
        <h2>{t("newsArticlePage.subscribeToLatestResearch")}</h2>

        <SubscriptionForm />
      </section>

      {relatedProducts.length > 0 && (
        <section className="related-products-section">
          <h2>{t("newsArticlePage.relatedProducts")}</h2>
          <HorizontalSlider products={relatedProducts} />
        </section>
      )}

      {relatedArticles.length > 0 && (
        <section className="related-articles-section">
          <h2>{t("newsArticlePage.relatedArticles")}</h2>
          <div className="related-articles-grid">
            {relatedArticles.map((relatedArticle) => (
              <div key={relatedArticle.id} className="related-article-card">
                <a href={`/news/${relatedArticle.id}`}>
                  <img
                    src={relatedArticle.image}
                    alt={relatedArticle.title}
                    className="related-article-image"
                  />
                  <h3>{relatedArticle.title}</h3>
                </a>
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export default NewsArticlePage;
