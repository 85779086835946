import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaBars, FaTimes } from "react-icons/fa";
import SearchBar from "./SearchBar"; // Ensure the correct path
import "../styles/Header.css";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [products, setProducts] = useState([]);
  const { t, i18n } = useTranslation(); // Use the i18n instance

  const navigate = useNavigate(); // Ensure useNavigate is used only if needed

  // Fetch products based on the current language
  const fetchProducts = (language) => {
    console.log(`Fetching products for language: ${language}`);
    const PRODUCTS_JSON_URL = language === "pt-BR" ? "/products_pt.json" : "/products_en.json";
   
    fetch(PRODUCTS_JSON_URL)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.products);
      })
      .catch((error) => console.error("Error fetching products:", error));
  };

  // Load products when the component first mounts
  useEffect(() => {
    fetchProducts(i18n.language);

    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobile(false); // Reset mobile menu on larger screens
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Listen for language changes and refetch products
  useEffect(() => {
    fetchProducts(i18n.language); // Fetch products whenever the language changes
  }, [i18n.language]);

  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
  };

  const handleLinkClick = () => {
    if (isMobile) {
      setIsMobile(false); // Close the mobile menu only if it's open
    }
  };

  // Handle language change and trigger component re-render
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang); // Change language in i18n
    fetchProducts(lang); // Immediately refetch products after language change
    console.log(`Language changed to: ${lang}`);
  };

  return (
    <header className="header">
      <nav className="navbar">
        <div className="menu-icon" onClick={toggleMobileMenu}>
          {isMobile ? <FaTimes /> : <FaBars />}
        </div>
        <h1 className="logo">FanClube</h1>

        <ul
          className={
            isMobile ? "nav-links nav-links-mobile active" : "nav-links"
          }
        >
          <li>
            <Link to="/" onClick={handleLinkClick}>
              {t("header.home")}
            </Link>
          </li>

          <li>
            <Link to="/news" onClick={handleLinkClick}>
              {t("header.news")}
            </Link>
          </li>
        </ul>

        {/* Language Switcher */}
        <div className="language-switcher">
          <button onClick={() => handleLanguageChange("en")}>EN</button>
          <button onClick={() => handleLanguageChange("pt-BR")}>PT-BR</button>
        </div>

        {/* Reuse the enhanced SearchBar component */}
        <SearchBar products={products} />
      </nav>
    </header>
  );
};

export default Header;
