import React, { useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import ProductGrid from "../components/ProductGrid";

const MenPage = () => {
  const [products, setProducts] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    // Fetch products from the JSON file
    const userLanguage = getUserLanguage();

    // Set the URL dynamically based on the user's language
    const PRODUCTS_JSON_URL = userLanguage === 'pt' ? '/products_pt.json' : '/products_en.json';
    fetch(PRODUCTS_JSON_URL)
      .then((response) => response.json())
      .then((data) => {
        // Filter products for the "Men" category
        const menProducts = data.products.filter(
          (product) => product.category === "Men"
        );
        setProducts(menProducts);
      })
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  return (
    <div>
      <h2>{t("homepage.menCollection")}</h2>
      <ProductGrid products={products} />
    </div>
  );
};

export default MenPage;
