import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "../styles/HorizontalSlider.css";

const HorizontalSlider = ({ products }) => {
  const { t } = useTranslation();
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    let scrollInterval;

    const startAutoScroll = () => {
      scrollInterval = setInterval(() => {
        if (slider.scrollLeft + slider.clientWidth >= slider.scrollWidth) {
          slider.scrollLeft = 0;
        } else {
          slider.scrollLeft += 2;
        }
      }, 20);
    };

    startAutoScroll();

    return () => {
      clearInterval(scrollInterval);
    };
  }, []);

  return (
    <div className="horizontal-slider" ref={sliderRef}>
      <div className="slider-container">
        {products.map((product) => (
          <div className="slider-item" key={product.id}>
            <img
              src={product.image}
              alt={product.name}
              className="slider-image"
            />
            <h3 className="product-name">{t(product.name)}</h3>
            <a
              href={product.affiliateLink}
              target="_blank"
              rel="noopener noreferrer"
              className="buy-now-button"
            >
              {t("newsArticlePage.buyNow")}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalSlider;
