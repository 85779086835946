import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductGrid from "../components/ProductGrid";
import { getUserLanguage } from "../utils";

const WomenPage = () => {
  const [products, setProducts] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const userLanguage = getUserLanguage();
    const PRODUCTS_JSON_URL = userLanguage === 'pt' ? '/products_pt.json' : '/products_en.json';
    // Fetch products from the JSON file
    fetch(PRODUCTS_JSON_URL)
      .then((response) => response.json())
      .then((data) => {
        // Filter products for the "Women" category
        const womenProducts = data.products.filter(
          (product) => product.category === "Women"
        );
        setProducts(womenProducts);
      })
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  return (
    <div>
      <h2>Women's Collection</h2>
      <ProductGrid products={products} />
    </div>
  );
};

export default WomenPage;
