import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import KidsPage from "./pages/KidsPage";
import TeamsPage from "./pages/TeamsPage";
import NewsPage from "./pages/NewsPage";
import MenPage from "./pages/MenPage";
import WomenPage from "./pages/WomenPage";
import SearchResults from "./components/SearchResults";
import NewsArticlePage from "./pages/NewsArticlePage"; // Import the new component
import CategoryPage from "./pages/CategoryPage";
import CookieConsent from "./components/CookieConsent";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <Router>
      
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/kids" element={<KidsPage />} />
        <Route path="/teams" element={<TeamsPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/men" element={<MenPage />} />
        <Route path="/women" element={<WomenPage />} />
        <Route path="/search-results" element={<SearchResults />} />{" "}
        <Route path="/news/:id" element={<NewsArticlePage />} />{" "}
        <Route path="/category/:categoryName" element={<CategoryPage />} />
        {/* Dynamic route for news articles */}
        {/* Ensure this is correctly defined */}
      </Routes>
      <CookieConsent />
      <Analytics />
    </Router>
  );
}

export default App;
