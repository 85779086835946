import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translation files
import enTranslation from "./locales/en/translation.json";
import ptBRTranslation from "./locales/pt-BR/translation.json";

i18n
  .use(LanguageDetector) // Automatically detect the user's language
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      "pt-BR": {
        translation: ptBRTranslation,
      },
    },
    fallbackLng: "en", // Fallback to English if language is not available
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    detection: {
      // Options for language detection
      order: ["querystring", "localStorage", "navigator"],
      caches: ["localStorage"],
    },
  });

export default i18n;
