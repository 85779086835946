import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import ProductGrid from "../components/ProductGrid";
import "../styles/ComparisonTable.css";
import HorizontalSlider from "../components/HorizontalSlider"; // For related articles

const CategoryPage = () => {
  const { categoryName } = useParams();
  const location = useLocation();
  const { t, i18n } = useTranslation(); // Added i18n to track language changes
  const [products, setProducts] = useState(location.state?.products || []);
  const [categoryDescription, setCategoryDescription] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  // Normalize the category name for comparison
  const normalizedCategoryName = categoryName.toLowerCase().trim();

  // Fetch products if not provided via location.state or when language changes
  useEffect(() => {
    const fetchProducts = () => {
      const PRODUCTS_JSON_URL = i18n.language === 'pt-BR' ? '/products_pt.json' : '/products_en.json';
      
      if (!products.length) {
        fetch(PRODUCTS_JSON_URL)
          .then((response) => response.json())
          .then((data) => {
            const allProducts = data.products || [];
            const filteredProducts = allProducts.filter((product) =>
              product.categories.some(
                (category) => category.toLowerCase().trim() === normalizedCategoryName
              )
            );
            setProducts(filteredProducts);
          })
          .catch((error) => console.error("Error fetching products:", error))
          .finally(() => setLoading(false));
      } else {
        setLoading(false);
      }
    };

    fetchProducts(); // Fetch products when language changes
  }, [i18n.language, normalizedCategoryName, products]); // Re-run effect when language or category changes

  // Fetch category descriptions and related articles when language changes
  useEffect(() => {
    const fetchCategoryData = () => {
      const CATEGORIES_JSON_URL = i18n.language === 'pt-BR' ? '/categories_pt.json' : '/categories_en.json';
      const NEWS_JSON_URL = i18n.language === 'pt-BR' ? '/news_pt.json' : '/news_en.json';

      // Fetch category description
      fetch(CATEGORIES_JSON_URL)
        .then((response) => response.json())
        .then((data) => {
          const normalizedData = Object.keys(data).reduce((acc, key) => {
            acc[key.toLowerCase()] = data[key];
            return acc;
          }, {});

          if (normalizedData[normalizedCategoryName]) {
            setCategoryDescription(normalizedData[normalizedCategoryName].description);
          }
        })
        .catch((error) => console.error("Error fetching category descriptions:", error));

      // Fetch related articles
      fetch(NEWS_JSON_URL)
        .then((response) => response.json())
        .then((data) => {
          const related = data.news.filter((newsItem) =>
            products.some((product) =>
              product.categories.some((category) =>
                newsItem.productCategories.includes(category)
              )
            )
          );
          setRelatedArticles(related);
        })
        .catch((error) => console.error("Error fetching related articles:", error));
    };

    fetchCategoryData(); // Fetch category data when language changes
  }, [i18n.language, products, normalizedCategoryName]); // Re-run effect when language or category changes

  return (
    <div className="category-page">
      <Helmet>
        <title>{`${categoryName} | My Site`}</title>
        <meta name="description" content={categoryDescription || ""} />
      </Helmet>

      {relatedArticles.length > 0 && (
        <section className="related-articles-section">
          <h2>{t("categoryPage.relatedArticles")}</h2>
          <div className="related-articles-grid">
            {relatedArticles.map((relatedArticle) => (
              <div key={relatedArticle.id} className="related-article-card">
                <a href={`/news/${relatedArticle.id}`}>
                  <img
                    src={relatedArticle.image}
                    alt={relatedArticle.title}
                    className="related-article-image"
                  />
                  <h3>{relatedArticle.title}</h3>
                </a>
              </div>
            ))}
          </div>
        </section>
      )}

      <h1>{t("categoryPage.productsIn")} {categoryName}</h1>

      {loading ? (
        <div className="loading-spinner"></div>
      ) : products.length > 0 ? (
        <ProductGrid products={products} />
      ) : (
        <p>{t("categoryPage.noProductsFound")}</p>
      )}

      <h2>{t("categoryPage.productsComparison")}</h2>

      {categoryDescription && (
        <div
          className="category-description"
          dangerouslySetInnerHTML={{ __html: categoryDescription }}
        />
      )}
    </div>
  );
};

export default CategoryPage;
