import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles/HeroBanner.css";

const HeroBanner = () => {
  const { t, i18n } = useTranslation(); // Added i18n to track language changes
  const [products, setProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Fetch products from the external JSON file
    const fetchProducts = () => {
      const PRODUCTS_JSON_URL = i18n.language === 'pt-BR' ? '/products_pt.json' : '/products_en.json';
      
      fetch(PRODUCTS_JSON_URL)
        .then((response) => response.json())
        .then((data) => {
          // Create a map to store the first product of each category
          const categoryMap = {};

          data.products.forEach((product) => {
            product.categories.forEach((category) => {
              if (!categoryMap[category]) {
                categoryMap[category] = product;
              }
            });
          });

          // Extract the first product from each category
          const firstProducts = Object.values(categoryMap);
          setProducts(firstProducts);
        })
        .catch((error) => console.error("Error fetching products:", error));
    };

    fetchProducts(); // Fetch products when the language changes
  }, [i18n.language]); // Re-run when the language changes

  useEffect(() => {
    // Automatically move to the next slide every 5 seconds
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [products]); // Re-run when products change

  const handlePrevClick = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + products.length) % products.length
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
  };

  if (products.length === 0) {
    return <div>{t("loading")}</div>; // Internationalized loading message
  }

  const currentProduct = products[currentIndex];

  return (
    <section className="hero-slider">
      <div
        className="slide"
        style={{ backgroundImage: `url(${currentProduct.image})` }}
      >
        <div className="slide-content">
          <h1>{currentProduct.name}</h1>
          <p>{t("heroBanner.exclusiveGear")}</p> {/* Internationalized slogan */}
          <a
            href={currentProduct.affiliateLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="shop-now">{t("heroBanner.shopNow")}</button> {/* Internationalized button */}
          </a>
        </div>
      </div>
      <div className="slider-controls">
        <button className="prev" onClick={handlePrevClick}>
          &#10094;
        </button>
        <button className="next" onClick={handleNextClick}>
          &#10095;
        </button>
      </div>
      <div className="slider-dots">
        {products.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </section>
  );
};

export default HeroBanner;
