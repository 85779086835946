import React from "react";
import { useTranslation } from "react-i18next";
import "../styles/ProductGrid.css";
import { Helmet } from "react-helmet";

const ProductGrid = ({ products }) => {
  const { t } = useTranslation();
  const pageTitle = t('page.title');
  const pageDescription = t('page.description');


  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <div className="product-grid">
        {products.length > 0 ? (
          products.map((product) => (
            <div className="product-card" key={product.id}>
              <img
                src={product.image}
                alt={`${product.name} - Categorias: ${product.categories}`}
                className="product-image"
              />
              <h3 className="product-name">{product.name}</h3>
              {product.team && (
                <p className="product-team">{product.team}</p>
              )}{" "}
              {/* Check if team exists */}
              <a
                href={product.affiliateLink}
                target="_blank"
                rel="noopener noreferrer"
                className="buy-now-button"
              >
                {t("productGrid.buyNow")}
              </a>
            </div>
          ))
        ) : (
          <p>{t("productGrid.noProducts")}</p> // Display a message if no products exist
        )}
      </div>
    </div>
  );
};

export default ProductGrid;
