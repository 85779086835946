import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HeroBanner from "../components/HeroBanner";
import NewsBanner from "../components/NewsBanner";
import ProductGrid from "../components/ProductGrid";
import SubscriptionForm from "../components/SubscriptionForm";
import { useNavigate } from "react-router-dom";
import "../styles/HomePage.css";

const HomePage = () => {
  const { t, i18n } = useTranslation(); // Added i18n to track language changes
  const [categories, setCategories] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [categoryLinks, setCategoryLinks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [featuredArticles, setFeaturedArticles] = useState([]); // Placeholder for featured articles
  const navigate = useNavigate();

  // Function to fetch products based on language
  const fetchProducts = (language) => {
    const PRODUCTS_JSON_URL = language === "pt-BR" ? "/products_pt.json" : "/products_en.json";
    fetch(PRODUCTS_JSON_URL)
      .then((response) => response.json())
      .then((data) => {
        const categoryMap = {};
        data.products.forEach((product) => {
          product.categories.forEach((category) => {
            if (!categoryMap[category]) {
              categoryMap[category] = [];
            }
            if (categoryMap[category]) {
              categoryMap[category].push(product);
            }
          });
        });
        setCategories(categoryMap);
      })
      .catch((error) => console.error("Error fetching products:", error));
  };

  // Fetch products when component mounts and when language changes
  useEffect(() => {
    fetchProducts(i18n.language); // Fetch products when language changes
  }, [i18n.language]); // Re-fetch products when language changes

  const getCategoryTitle = (category) => {
    const translationKey = `homepage.${category.toLowerCase()}Collection`;
    return t(translationKey, { defaultValue: category });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const results = [];
    const links = [];
    Object.keys(categories).forEach((category) => {
      const productsInCategory = categories[category].filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (productsInCategory.length > 0) {
        results.push(...productsInCategory);
      }
      if (category.toLowerCase().includes(searchQuery.toLowerCase())) {
        links.push(category);
      }
    });
    setSearchResults(results);
    setCategoryLinks(links);
  };

  const handleCategoryClick = (category) => {
    const products = categories[category];
    navigate(`/category/${category.toLowerCase()}`, {
      state: { products },
    });
  };

  return (
    <div className="homepage">
      <HeroBanner /> {/* Enhanced HeroBanner with CTA */}
      <section className="subscription-section">
        <SubscriptionForm />
      </section>
      <section className="featured-articles">
        <h2>{t("homepage.featuredArticles")}</h2>
        {/* Replace with your actual component to display articles */}
        <div className="article-list">
          {featuredArticles.map((article, index) => (
            <div key={index} className="article-item">
              <h3>{article.title}</h3>
              <p>{article.summary}</p>
              <a rel="canonical" href={article.link}>
                {t("homepage.readMore")}
              </a>
            </div>
          ))}
        </div>
      </section>
      <NewsBanner /> {/* Display recent news */}
      <section className="affiliate-disclaimer">
        <p>{t("affiliateDisclaimer.message")}</p>
      </section>
      {searchResults.length > 0 && (
        <section className="search-results">
          <h2>{t("homepage.searchResults")}</h2>
          <ProductGrid products={searchResults} />
        </section>
      )}
      {categoryLinks.length > 0 && (
        <section className="category-links">
          <h2>{t("homepage.relatedCategories")}</h2>
          <ul>
            {categoryLinks.map((category) => (
              <li key={category}>
                <button
                  className="category-link-button"
                  onClick={() => handleCategoryClick(category)}
                >
                  {getCategoryTitle(category)}
                </button>
              </li>
            ))}
          </ul>
        </section>
      )}
      {Object.keys(categories).map((category) => (
        <section className="category-section" key={category}>
          <h2>
            <button
              className="category-link-button"
              onClick={() => handleCategoryClick(category)}
            >
              {getCategoryTitle(category)}
            </button>
          </h2>
          <ProductGrid products={categories[category]} />
          <button
            key={category}
            onClick={() => handleCategoryClick(category)}
            className="view-more"
          >
            {t("homepage.viewMore", { category: getCategoryTitle(category) })}
          </button>
        </section>
      ))}
    </div>
  );
};

export default HomePage;
