import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles/NewsPage.css";
import { getUserLanguage } from "../utils";

const NewsPage = () => {
  const { t } = useTranslation();
  const [news, setNews] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(t("newsBanner.all"));

  useEffect(() => {
    // Fetch the latest news
    const userLanguage = getUserLanguage();

    // Set the URL dynamically based on the user's language
    const NEWS_JSON_URL = userLanguage === 'pt' ? '/news_pt.json' : '/news_en.json';
   
    fetch(NEWS_JSON_URL)
      .then((response) => response.json())
      .then((data) => {
        setNews(data.news);
        setFilteredNews(data.news);
        const uniqueCategories = [
          t("newsBanner.all"),
          ...new Set(data.news.map((item) => item.category)),
        ];
        setCategories(uniqueCategories);
      })
      .catch((error) => console.error("Error fetching news:", error));
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    if (category === t("newsBanner.all")) {
      setFilteredNews(news);
    } else {
      setFilteredNews(news.filter((item) => item.category === category));
    }
  };

  return (
    <section className="news-page">
      <h2>{t("newsBanner.header")}</h2>

      <div className="news-categories">
        {categories.map((category) => (
          <button
            key={category}
            className={`category-button ${
              selectedCategory === category ? "active" : ""
            }`}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="news-list">
        {filteredNews.map((item) => (
          <div className="news-item" key={item.id}>
            <img
              src={item.image}
              alt={item.title}
              className="news-item-image"
            />
            <div className="news-item-content">
              <h3>{item.title}</h3>
              <p className="news-item-date">
                {new Date(item.date).toLocaleDateString()}
              </p>

              <a
                rel="canonical"
                href={`/news/${item.id}`}
                className="read-more"
              >
                {t("newsBanner.readMore")}
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default NewsPage;
