import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import "../styles/SearchBar.css";

const SearchBar = ({ products }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState({
    products: [],
    categories: [],
  });
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setSearchSuggestions({ products: [], categories: [] });
      return;
    }

    const lowercasedQuery = searchQuery.toLowerCase();

    const filteredProducts = products.filter(
      (product) =>
        product.name.toLowerCase().includes(lowercasedQuery) ||
        product.categories.some((category) =>
          category.toLowerCase().includes(lowercasedQuery)
        )
    );

    const filteredCategories = [
      ...new Set(
        products.flatMap((product) =>
          product.categories.filter((category) =>
            category.toLowerCase().includes(lowercasedQuery)
          )
        )
      ),
    ];

    setSearchSuggestions({
      products: filteredProducts.map((product) => ({
        type: "product",
        name: product.name,
        id: product.id,
        image: product.image, // Ensure each product has an 'image' field
      })),
      categories: filteredCategories.map((category) => ({
        type: "category",
        name: category,
      })),
    });
  }, [searchQuery, products]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchQuery.trim() === "") return;

    const lowercasedQuery = searchQuery.toLowerCase();

    const filteredProducts = products.filter(
      (product) =>
        product.name.toLowerCase().includes(lowercasedQuery) ||
        product.categories.some((category) =>
          category.toLowerCase().includes(lowercasedQuery)
        )
    );

    const filteredCategories = [
      ...new Set(
        products.flatMap((product) =>
          product.categories.filter((category) =>
            category.toLowerCase().includes(lowercasedQuery)
          )
        )
      ),
    ];

    navigate("/search-results", {
      state: {
        products: filteredProducts,
        categories: filteredCategories,
        searchQuery, // Pass the raw search query as well
      },
    });

    setSearchQuery(""); // Clear search query if desired
    setSearchSuggestions({ products: [], categories: [] });
    setIsFocused(false); // Close suggestions
  };

  const handleSuggestionClick = (suggestion) => {
    if (suggestion.type === "product") {
      const selectedProduct = products.find((p) => p.id === suggestion.id);
      navigate(`/product/${selectedProduct.id}`, {
        state: { product: selectedProduct },
      });
    } else if (suggestion.type === "category") {
      navigate(`/category/${suggestion.name.toLowerCase()}`);
    }
    setSearchQuery(""); // Optionally clear the search
    setSearchSuggestions({ products: [], categories: [] });
    setIsFocused(false); // Close suggestions
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit();
    }
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="search-bar-wrapper" ref={wrapperRef}>
      <div className="search-bar">
        <FaSearch className="search-icon" onClick={handleSearchSubmit} />
        <input
          type="text"
          placeholder="Search for products or categories..."
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown} // Trigger search on Enter key
          onFocus={() => setIsFocused(true)}
        />
      </div>
      {isFocused &&
        (searchSuggestions.products.length > 0 ||
          searchSuggestions.categories.length > 0) && (
          <div className="autocomplete-suggestions">
            {searchSuggestions.products.length > 0 && (
              <div className="suggestion-section">
                <h4>Products</h4>
                {searchSuggestions.products.map((product) => (
                  <div
                    key={product.id}
                    className="autocomplete-suggestion product-suggestion"
                    onClick={() => handleSuggestionClick(product)}
                  >
                    <img
                      src={product.image}
                      alt={product.name}
                      className="suggestion-image"
                    />
                    <span className="suggestion-name">{product.name}</span>
                  </div>
                ))}
              </div>
            )}
            {searchSuggestions.categories.length > 0 && (
              <div className="suggestion-section">
                <h4>Categories</h4>
                {searchSuggestions.categories.map((category, index) => (
                  <div
                    key={index}
                    className="autocomplete-suggestion category-suggestion"
                    onClick={() => handleSuggestionClick(category)}
                  >
                    <span className="suggestion-name">{category.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
    </div>
  );
};

export default SearchBar;
