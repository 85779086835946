import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProductGrid from "./ProductGrid";
import "../styles/SearchResults.css";

const SearchResults = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { products, categories } = location.state || {
    products: [],
    categories: [],
  };
  const { t } = useTranslation(); // Updated to use 'teamsPage' namespace

  const handleCategoryClick = (category) => {
    navigate(`/category/${category.toLowerCase()}`, {
      state: { products }, // Pass products to the category page
    });
  };

  return (
    <div>
      <h2>{t("searchResults.title")}</h2>

      {categories.length > 0 && (
        <div className="search-category-results">
          <h3>{t("searchResults.categoriesTitle")}</h3>
          <div className="category-grid">
            {categories.map((category) => (
              <div
                key={category}
                className="category-card"
                onClick={() => handleCategoryClick(category)}
              >
                <h4>{category}</h4>
              </div>
            ))}
          </div>
        </div>
      )}

      {products.length > 0 ? (
        <ProductGrid products={products} />
      ) : (
        <p>{t("searchResults.noProductsFound")}</p>
      )}
    </div>
  );
};

export default SearchResults;
