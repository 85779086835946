import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductGrid from "../components/ProductGrid";
import { getUserLanguage } from "../utils";

const TeamsPage = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(""); // No default selected team initially
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const userLanguage = getUserLanguage();
    const PRODUCTS_JSON_URL = userLanguage === 'pt' ? '/products_pt.json' : '/products_en.json';
    // Fetch products from the external JSON file
    fetch(PRODUCTS_JSON_URL)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.products);
        extractTeams(data.products);
      })
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  const extractTeams = (products) => {
    // Extract unique team names from products
    const uniqueTeams = [...new Set(products.map((product) => product.team))];
    setTeams(uniqueTeams);
    if (uniqueTeams.length > 0) {
      setSelectedTeam(uniqueTeams[0]); // Set the first team as the default selected team
    }
  };

  // Filter products by the selected team
  const filteredProducts = products.filter(
    (product) => product.team === selectedTeam
  );

  return (
    <div>
      <h2>{t("teamsPage.shopByTeam")}</h2>
      <div className="team-selection">
        {teams.map((team) => (
          <button key={team} onClick={() => setSelectedTeam(team)}>
            {t(
              `teamsPage.teams.${team.replace(/\s+/g, "").toLowerCase()}`,
              team
            )}
          </button>
        ))}
      </div>
      <ProductGrid products={filteredProducts} />
    </div>
  );
};

export default TeamsPage;
