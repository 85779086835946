import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserLanguage } from '../utils'; // Reuse the same utility function

import "../styles/NewsBanner.css";

const NewsBanner = () => {
  const { t, i18n } = useTranslation(); // Added i18n to track language changes
  const [news, setNews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Fetch the latest news based on the selected language
    const fetchNews = () => {
      const NEWS_JSON_URL = i18n.language === 'pt-BR' ? '/news_pt.json' : '/news_en.json';

      fetch(NEWS_JSON_URL)
        .then((response) => response.json())
        .then((data) => setNews(data.news.slice(0, 5))) // Get the latest 5 news articles
        .catch((error) => console.error("Error fetching news:", error));
    };

    fetchNews(); // Call the function to fetch news whenever the language changes
  }, [i18n.language]); // Re-run effect when the language changes

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % news.length);
    }, 5000); // Change the news item every 5 seconds
    return () => clearInterval(interval); // Clear the interval on component unmount
  }, [news]); // Re-run effect when the news changes

  if (news.length === 0) {
    return <div>{t("newsBanner.loading")}</div>;
  }

  const currentNews = news[currentIndex];

  return (
    <section className="news-slider">
      <div
        className="slide"
        style={{ backgroundImage: `url(${currentNews.image})` }}
      >
        <div className="slide-content">
          <div
            dangerouslySetInnerHTML={{
              __html: currentNews.content.substring(0, 150) + "...",
            }}
          />
          <a
            rel="canonical"
            href={`/news/${currentNews.id}`}
            className="read-more"
          >
            {t("newsBanner.readMore")}
          </a>
        </div>
      </div>
      <div className="slider-controls">
        <button
          className="prev"
          onClick={() =>
            setCurrentIndex(
              (prevIndex) => (prevIndex - 1 + news.length) % news.length
            )
          }
        >
          &#10094;
        </button>
        <button
          className="next"
          onClick={() =>
            setCurrentIndex((prevIndex) => (prevIndex + 1) % news.length)
          }
        >
          &#10095;
        </button>
      </div>
      <div className="slider-dots">
        {news.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </section>
  );
};

export default NewsBanner;
